<template>
    <vx-card>
        <h4>Top {{ long === 0? '' : long}} villes</h4>
        <br/>
        <vs-table :data="city">		
            <!-- header table -->
            <template slot="thead">
                <vs-th sort-key="name">Ville</vs-th>
                <vs-th sort-key="cp">CP</vs-th>
                <vs-th sort-key="pourcent">%</vs-th>
                <vs-th sort-key="nb">Nombre</vs-th>
            </template>

            <!--Body table-->
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>
                    <vs-td :data="data[indextr].cp">
                        {{ data[indextr].cp }}
                    </vs-td>
                    <vs-td :data="data[indextr].pourcent">
                        {{ data[indextr].pourcent }}
                    </vs-td>
                    <vs-td :data="data[indextr].nb">
                        {{ data[indextr].nb }}
                    </vs-td>
                </vs-tr>
            </template>
		</vs-table>
    </vx-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import Member from '@/database/models/member'

export default {
    props: ['long'],
    data(){
        return{
            city:[],
        }
    },
    mounted:function()
	{
        this.update();
    },
    methods:{
        update()
        {
           this.city = [];
           Member.getTabAllMemory((list)=>
           {
               let nb_total = 0;
               let tab = [];
               //triage
               for( var i=0; i<list.length; i++)
               {
                   let buffer = list[i];
                   let cp = buffer.postal_code;

                   if(cp)
                   {
                        if(tab[cp]==null)
                        tab[cp] = {
                            name     : buffer.city,
                            cp       : cp,
                            pourcent : 0,
                            nb       : 0,
                        };

                        tab[cp].nb++;
                        nb_total++;
                   }
               }

               //calcule % + create final liste
               let tab2 = [];
               for( var p in tab )
               {
                    tab[p].pourcent = ((tab[p].nb*100)/nb_total).toFixed(2);
                    tab2.push({
                        name     : tab[p].name.toUpperCase(),
                        cp       : tab[p].cp,
                        pourcent : tab[p].pourcent,
                        nb       : tab[p].nb,
                    });
               }

               //triage
                tab2.sort(function(a, b)
                {
                    if(a.nb>b.nb)
                        return -1
                    if(a.nb<b.nb)
                        return 1;
                    return 0;
                });

               //final
               this.long === 0? this.city = tab2 : this.city = tab2.slice(0, this.long)
           });
        }
    }
}

</script>