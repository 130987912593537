<template>
	<div>
		<h4>Votre clientèle.</h4>
		<br/>
		<vs-row>
            <vs-col vs-xs="12" vs-sm="12" vs-lg="6" class="espace-left">
                <StatType />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="12" vs-lg="6" class="espace-right">
                <StatGender />
            </vs-col>
        </vs-row>
		<br/>
		<StatCity ref="StatCity" :long="0" />
	</div>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 900px)
{
    .espace-left
    {
        padding-right:10px;
    }
    .espace-right
    {
        padding-left:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .espace-left
    {
        padding-right:0px;
        margin-bottom:20px;
    }
    .espace-right
    {
        padding-left:0px;
    }
}
</style>

<script>
import StatCity from '@/components/stats/city.vue'
import StatGender from '@/components/stats/gender.vue'
import StatType from '@/components/stats/type.vue'


export default {
    components:{
		StatCity,
		StatGender,
		StatType
    },
    data(){
        return{

        }
    },
    methods:{

    }
};
</script>